
.Menu li select{
    font-size: 1rem;
    border-width:.1125rem;
    border-left: 1px;
    padding: .33rem;
    border-top: 1px;
    border-color: gold;
    border-right: 1px;
    width:90%;
    color:white;
    background-color: transparent;
    

}

.Menu li select option{
    background-color:slategray;
    border:none;
    
}

p{
    text-shadow: none;
}
.Menu li{
    display: flex;
    justify-content: center;
    width:100%; 
    align-items: center;
   
    
    margin-top:.5rem;
  
}

.Menu ul{
    
    list-style: none;
    margin:0;
    padding:0;
    align-items: center;
    width:100%;
    padding-bottom:1rem;
}

h2.FilterHeader  {

    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
   text-decoration:underline;
   color:gold;
}

.AccordionSummary
{
    text-align: center;
    
    background-color: black;
}