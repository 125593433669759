.overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 0; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
 




.LogoArea{
display:flex;
justify-content: center;
align-items: center;

}



span.Logotext, .DataContainer h2, .DataContainerHeader h2{
    
    
    font-size: 4rem;
    color:gold;
    font-family: Lora,serif;
    margin-left:.1rem;
  }
  img.Logo{
    
   margin-top:5px;
    height:3.333rem;
    color:gold;
    font-family: Lora,serif;
  }

.Accordion h2{
    display: flex
    ;
    justify-content: center;
text-align: center;
align-items: center;

}
.QueenCards.Card {
    background-color: black !important;
}
.Button.legendary:hover{
    background-color: gold;
}
.Button.epic:hover{
    background-color: purple;
}
.Button.rare:hover{
    background-color: blue ;
}
.Button.common:hover{
    background-color: greenyellow;
}

.QueenCards.legendary{
    background-color: gold;
}
.QueenCards.epic{
    background-color: purple;
}
.QueenCards.rare{
    background-color: blue;
}
.QueenCards.common{
    background-color: greenyellow;
}

.Button.Legendary{
    background-color: gold;
}
.Button.Epic{
    background-color: purple;
}
.Button.Rare{
    background-color: blue;
}
.Button.Common{
    background-color: greenyellow;
}

.QueenCards{
    margin:.4rem;
    
  
}
.idAndRarity{
    display:flex;
    flex-direction: column;
   
    align-items: center;
}

.idAndRarity > button {
    margin: .5rem 0rem 0rem 0rem;
}

.Collection{
    background-color: #8e5bc1;
    color: white;
 
}

.Collection p{
    text-align: center;
}




.Menu{
    width:100%;
display: flex;
    flex-direction: column;
    align-items: center;

}
.Menu li {
    list-style: none;
}
.Menu select{
   
}

.FilterControl button{

margin-top:10px;

}
h1{
    
    display:flex;
    align-items: center;
    justify-content: center;
}
.rarityText {
    text-align: center;
    color:#000;
}
.statsbutton{
    display: flex;
    margin:auto;

    
}
.statsbutton button{
  
}

.QueenCards {
    flex-basis:23%;
    padding:0;
   flex-grow:1;
   margin:1rem;
   /* I'd like to set the max-width to 13rem, then on mobile increase to 16 */
   /* But for some reason its not respecting my media queries
   3/21/2024 
   */
   max-width:13.5rem;
   min-width:12.3rem;
   /*
   max-height:20rem;
   min-height:16rem;
   */

}

.QueenCards img {
    min-height:11rem;
    min-width:11rem;
    aspect-ratio: 1/1 !important;

}


.DoubleContainer{
    display:flex;
 
}


.GalleryContainer{
    flex-grow:1;
    flex-wrap:wrap;
    display:flex;
    width:100%;
    justify-content: center;
    align-items:center;
    padding:0rem 1rem;
 
}

.MenuContainer{
    width:25%;
    margin-top:1rem;
    min-width:15rem;

}

.MuiAccordionSummary{
    text-decoration: overline   ;
}
@media only screen and (max-width: 700px) {
    span.Logotext{
    
    
        font-size: 2.2rem;
    }

    img.Logo{
        height:1.9rem;

       
    }

    .Logo{
        .Logo {
            align-self: start; /* or any other value like center, end, etc. */
            margin-bottom: 10px;
            height: 3.333rem; /* Adjust the height as needed */
            color: gold; /* This won't affect an image */
            font-family: Lora, serif; /* This won't affect an image */
          }
          
    }
   
   
    h2.FilterHeader  {

        font-size: 1.75rem;
    }

    .DoubleContainer {
        width:100%;
        
        flex-direction: column;
     align-items:center;
      }
   
    .GalleryContainer {
      width:75%;
      flex-direction: column;
    
      
    }

    .QueenCards{
        margin:0rem 0rem .80rem 0rem;
        min-width:16rem;
        min-height:22.75rem;
        max-height:31.28rem;
    }
    .MenuContainer {
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        list-style: none;
      }
.MenuContainer ul{
    list-style:none;
    padding:0;
    padding-bottom:1rem;
}

      .MenuContainer li {
        width:100%;
       
        display:flex;
        padding:5px 0px 5px 0px;
      }

      .MenuContainer Button {
        width:100%;
      }

      .filterButtons {
        display:flex;
        flex-direction: row;
        flex-grow: 1;
        flex-wrap: wrap;

      }
  

  }

.GalleryContainer img {
    width:100%;
}



/*
@media screen and (min-width: 800px) {
    img {
        height:300px;
        };
    }
*/