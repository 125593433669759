
.DrawerLogoArea{
    display:flex;
width:100%;
justify-content: center;
padding: 0rem .2rem;   
padding-top:1rem; 
    }
    
    
    
    span.DrawerLogoText, .DataContainer h2{
        
      
        font-size: 2.9rem;
        color:gold;
        font-family: Lora,serif;
      
      }
      .DrawerLogo{
      height:1.9rem;
      }


      @media only screen and (max-width: 900px) {
        span.DrawerLogoText, .DataContainer h2{
        
      
          font-size: 2rem;
          color:gold;
          font-family: Lora,serif;
        
        }
        .DrawerLogo{
        height:1.5rem;
        }
      }

