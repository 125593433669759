.DataContainer{
    padding:1rem;
    max-width:80%;
    max-height:75%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    
}


.DataContainer h2{
   
    color:black;
    font-size: 3rem;
    text-align: center;
    width:100%;
}

.DataContainer span{
   
    color:black;
    font-size: 1.5rem;
    text-align: center;
    width:100%;
    
}

.top {height:30%;
text-align: center;
justify-content: center;
display: flex;
width:100%;
align-items:start;
flex-direction: column;}

.bottom{height:60%;
    width:100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;
justify-content: center;
}

.DataContainer span{
    border: #000;
    border-color: gold;
    border-width: 1px;
    
}

.DataContainer li
{
   
    list-style: none;
    font-size:.65rem;
    padding:5px;
    
    
}

.DataContainer ul {
    display: flex;
    justify-content: center;
}
.specialTraits{
    
    height:50%;
    background-color: blue;
    width:100%;
}

.specialTraits{
    display: flex;
    flex-flow: column;
}
.NFTData{
    display: flex;
    justify-content: center;
    height:50%;
    width:100%;
    padding-bottom: 2rem;
}
.NFTData ul {
   
    display:flex;
    height:100%;
    overflow: auto;
    
   flex-wrap: wrap;
    

    width:100%;
    
    
    font-family:'Courier New', Courier, monospace;
    
    background-color: purple;
    color:gold;
}

.DataContainer{
    z-index: 10;
    overflow:auto;
    position:fixed;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    background-color: white;
    border: blue;
    border: 1px;
    transform: translate(-50%, -50%); /* New */
   
}

@media only screen and (max-width: 649px) {
.DataContainer{
    z-index: 10;
    overflow:auto;
    position:fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: white;
    border: blue;
    border: 1px;
    transform: translate(-50%, -50%); /* New */
    
}
}


.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}